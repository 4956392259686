import React from "react"
import './layout.css'

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <>
        <div class="">

          <div
            class="text-2xl"
          >
            <main
            style={{
                margin: `0 auto`,
                padding: `2.5rem 2.5rem`
              }}
            >
              {children}
            </main>
          </div>
        </div>
      </>
    )
  }
}

export default Layout
